import React, { useState } from "react";

import { Button } from "../buttonElement";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./heroelements";
import { animateScroll as scroll } from "react-scroll";
import desktopImage from "../../videos/websitebanner.png";
import mobileImage from "../../videos/websitebannerSmall.png";

const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg src={imageUrl} alt=""></VideoBg>
      </HeroBg>
      <HeroContent>
        <HeroH1>😈Pocket Demons😈 </HeroH1>
        <HeroP>Breeding, Staking, P2E.</HeroP>
        <HeroBtnWrapper>
          <Button
            href="https://app.ebisusbay.com/collection/pocket-demons"
            target="_blank"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Ebisus Bay {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
