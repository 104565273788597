import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  Chain,
  midnightTheme,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import App from "./App";

const cronosChain = {
  id: 25,
  name: "Cronos mainnet",
  network: "cronos",
  iconUrl: "https://cronoscan.com/images/brandassets/logo.jpg?v=22.11.3.0",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Cro",
    symbol: "CRO",
  },
  rpcUrls: {
    default: "https://evm-cronos.crypto.org",
  },
  blockExplorers: {
    default: { name: "Cronoscan", url: "https://cronoscan.com/" },
    etherscan: { name: "Cronoscan", url: "https://cronoscan.com/" },
  },
  testnet: false,
};

const { chains, provider, webSocketProvider } = configureChains(
  [cronosChain],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

const { connectors } = getDefaultWallets({
  appName: "RainbowKit demo",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider
      modalSize="compact"
      theme={midnightTheme({
        accentColor: "#8A2BE2	",
      })}
      coolMode
      chains={chains}
    >
      <App />
    </RainbowKitProvider>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
