import "./login.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";

function Login() {
  const { address, isConnected } = useAccount();
  const [userWallet, setUserWallet] = useState();
  const [discordConnected, setDiscordConnected] = useState(false);

  const [discordUser, setDiscordUser] = useState("");
  const getUserDetails = useCallback(async (accessToken, tokenType) => {
    try {
      const response = await axios.get("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`,
        },
      });
      const { username, discriminator, id } = response.data;
      console.log(response.data);
      setDiscordUser(id);
      console.log(`user ID: ${id}, username: ${username}#${discriminator}`);
      setDiscordConnected(true);
    } catch {
      console.log("error getting user");
    }
  }, []);

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type"),
    ];
    getUserDetails(accessToken, tokenType);
  }, [getUserDetails]);

  useEffect(() => {
    if (isConnected) {
      setUserWallet(address);
      console.log(userWallet);
    }
  }, [isConnected, address, userWallet]);

  useEffect(() => {
    if (discordConnected === true) {
      console.log(`Discord Connected! user id: ${discordUser}`);
    }
  });

  useEffect(() => {
    if (discordConnected === true && address) {
      console.log(`Saving data into the Database...`);

      async function fetchData() {
        await axios.post(
          "https://pd-db.onrender.com/api/putData",
          {
            wallet: address,
            discordID: discordUser,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
      }
      fetchData();
    }
  }, [discordConnected, address, discordUser]);

  return (
    <div className="Login">
      <div className="bg"></div>
      <div className="wallet-connect-btn">
        <ConnectButton />
      </div>
      {isConnected && (
        <div className="wallet-connection-info">
          <h1 className="Hi1">Wallet is connected, please connect Discord</h1>
        </div>
      )}

      <a href="https://discord.com/api/oauth2/authorize?client_id=1044746111820234822&redirect_uri=https%3A%2F%2Fwww.pocketdemons.net%2Flogin&response_type=token&scope=identify">
        {" "}
        <button className="discord-btn">Connect Discord</button>
      </a>
      {discordConnected === true && isConnected === true && (
        <h1 className="Hi1">
          Game initialized. You can close this window, enjoy the game!
        </h1>
      )}
    </div>
  );
}

export default Login;
