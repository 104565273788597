import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { Button } from "../buttonElement";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
  BigText,
  BigText2,
  hasButton,
} from "./infoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  destination,
  bigText,
  bigText2,
  hasButton,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <AnimationOnScroll animateIn="animate__fadeIn">
                  <TopLine>{topLine}</TopLine>
                </AnimationOnScroll>
                <AnimationOnScroll delay={500} animateIn="animate__fadeIn">
                  <Heading lightText={lightText}>{headline} </Heading>
                </AnimationOnScroll>
                <AnimationOnScroll delay={1000} animateIn="animate__fadeIn">
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                  <BigText lightText={lightText}>{bigText}</BigText>
                  <BigText2 lightText={lightText}>{bigText2}</BigText2>
                </AnimationOnScroll>
                {hasButton === true && (
                  <AnimationOnScroll delay={1500} animateIn="animate__fadeIn">
                    <BtnWrap>
                      <Button
                        href={destination}
                        target="_blank"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </Button>
                    </BtnWrap>
                  </AnimationOnScroll>
                )}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                  <Img src={img} alt={alt} />
                </AnimationOnScroll>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
