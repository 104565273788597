export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: false,
  lightTextDecs: true,
  topLine: "About",
  headline: "666 Supply",
  description:
    "THE POCKET DEMONS PROJECT IS A COLLECTION OF 666 UNIQUELY GENERATED DEMON CHARACTERS. EACH DEMON IS GENERATED FROM A POOL OF OVER 70 TRAITS. THESE TRAITS WILL PLAY A PART IN OUR DEMON VS DEMON CARD GAME!",
  buttonLabel: "Join Discord",
  imgStart: true,
  img: require("../../images/img1.png"),
  alt: "",
  dark: true,
  primary: true,
  darkText: true,
  destination: "https://discord.gg/w6c6P6rvFK",
  hasButton: true,
};

export const homeObjTwo = {
  id: "tokenomics",
  lightBg: true,
  lightText: false,
  lightTextDecs: false,
  topLine: "",
  headline: "Tokenomics",
  description:
    "CRO WILL BE THE HEART OF OUR ECOSYSTEM. WITH THIS TOKEN YOU WILL HAVE MANY UTILITIES AND OPPORTUNITIES TO EARN CRO BY BATTLING YOUR DEMONS VS YOUR OPPONENTS!",
  imgStart: false,
  img: require("../../images/img3.png"),
  alt: "",
  dark: false,
  primary: false,
  darkText: true,
  buttonLabel: "Twitter",
  destination: "https://twitter.com/PDemon_Official",
  hasButton: true,
};

export const homeObjThree = {
  id: "p2e",
  lightBg: false,
  lightText: false,
  lightTextDecs: true,
  topLine: "P2E game",
  headline: "Risk to earn CRO",
  description:
    "HELL TRUMP WILL BE A CARD GAME VIA DISCORD WHICH WILL BASED AROUND THE POCKET DEMONS, EACH CARD WILL REPRESENT A DEMON AND THIER STATS SO ALL 666 CARDS WILL BE UNIQUE AND DIFFERENT. THE GAME WILL BE A HEAD-TO-HEAD BATTLE AGAINST YOUR OPPONENT IN A 5 ROUND BATTLE AND THE FIRST TO 3 ROUNDS WON WILL WIN THE BATTLE!",
  buttonLabel: "Join Discord",
  imgStart: true,
  img: require("../../images/img2.png"),
  alt: "",
  dark: false,
  primary: false,
  darkText: true,
  destination: "https://discord.gg/w6c6P6rvFK",
  hasButton: false,
};

export const homeObjFour = {
  id: "breeding",
  lightBg: true,
  lightText: false,
  lightTextDecs: false,
  topLine: "Create rare offsprings",
  headline: "Breeding",
  description:
    "BREEDING WILL BE AVAILABLE AFTER GEN.1 AND GEN.2. CREATE A MORE RARE OFFSPRING. THIS OFFSPRING CAN EITHER GET SOLD OR USED FOR UTILITY. ",
  imgStart: false,
  img: require("../../images/img4.png"),
  alt: "TwitterSpaces",
  dark: false,
  primary: false,
  darkText: true,
  buttonLabel: "Twitter",
  destination: "https://twitter.com/PDemon_Official",
  hasButton: false,
};

export const homeObjFive = {
  id: "staking",
  lightBg: false,
  lightText: false,
  lightTextDecs: true,
  topLine: "Staking",
  headline: "Stake to earn $HELL",
  description:
    "A NEW EXCITING STAKING PLATFORM IS ON THE WAY, ALLOWING YOU TO EARN $HELL BY STAKING YOUR NFTs",
  buttonLabel: "Join Discord",
  imgStart: false,
  img: require("../../images/img2.png"),
  alt: "THNDR token",
  dark: false,
  primary: false,
  darkText: true,
  destination: "https://discord.gg/w6c6P6rvFK",
  hasButton: false,
};
