import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import Icon1 from "../../images/consulting/img1.png";
import Icon2 from "../../images/consulting/img2.png";
import Icon6 from "../../images/consulting/img6.png";
import Icon9 from "../../images/consulting/img9.png";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./serviceselements";

const Team = () => {
  return (
    <ServicesContainer id="team">
      <ServicesH1>Meet the team</ServicesH1>
      <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2>Demon NotBased</ServicesH2>
            <ServicesP>Founder</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>Demon Richie</ServicesH2>
            <ServicesP>Founder</ServicesP>
          </ServicesCard>       
          <ServicesCard>
            <ServicesIcon src={Icon6} />
            <ServicesH2>Demon Secret Jen</ServicesH2>
            <ServicesP>Community Manager</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon9} />
            <ServicesH2>Demon Brian</ServicesH2>
            <ServicesP>Developer</ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </AnimationOnScroll>
    </ServicesContainer>
  );
};

const RoadMap = () => {
  return (
    <ServicesContainer id="roadmap">
      <ServicesH1>Roadmap</ServicesH1>
      <AnimationOnScroll animateIn="animate__fadeInRightBig">
        <ServicesWrapper>
          <ServicesCard>
            <ServicesH2>PHASE 1</ServicesH2>
            <ServicesP>• Project Takeover Gen 1 ✔</ServicesP>
            <ServicesP>• KYC✔</ServicesP>
            <ServicesP>• NFT Utility✔</ServicesP>
            <ServicesP>• Discord & Twitter Creation ✔ </ServicesP>
            <ServicesP>• Roadmap ✔</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesH2>PHASE 2</ServicesH2>
            <ServicesP>• New Website ✔</ServicesP>
            <ServicesP>
              • Poker Nights & Events - There will be weekly events ✔
            </ServicesP>
            <ServicesP>• Community Building & Giveaways ✔</ServicesP>
            <ServicesP>• Game Beta Testing v0.1 ✔</ServicesP>
            <ServicesP>
              • Fallen Angels Launch - Samples being created
            </ServicesP>
            <ServicesP>• Mint Party</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesH2>PHASE 3</ServicesH2>
            <ServicesP>• Fallen Angels Utility</ServicesP>
            <ServicesP>• Game Deployment</ServicesP>
            <ServicesP>• Gen 3 Art Work</ServicesP>
            <ServicesP>• Create Breeding Contract</ServicesP>
            <ServicesP>• Launchpad Development</ServicesP>
            <ServicesP>• Breeding Launch</ServicesP>
            <ServicesP>• Mint Party</ServicesP>
            <ServicesP>• Game Upgrades</ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </AnimationOnScroll>
    </ServicesContainer>
  );
};

export { Team, RoadMap };
